
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
    border-spacing: 0;
    border: 1px solid black;
}

table input.form-control-sm{
  max-width: 130px;
}

table tr th:last-child{
  width: 30px
}

.field{
  margin-bottom: 20px;
}

.rs-input-group .rs-btn-primary {
  max-width: 100px;
}

.spinner {
  animation: spin infinite 2s linear;
  margin: 50px auto;
  display: block;
}

@keyframes spin {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}


